import React from "react"
import { Link } from "gatsby"
import { ArrowRight } from "react-feather"
import { useTranslation } from "react-i18next"
import { getDataManagementLink, getIndustryNewsLink, getTLD } from "utils/general/links"

export const EventContactUsBlock = () => {
  const {t} = useTranslation()
	return (
    <div className="w-80 shrink-0 flex flex-col gap-12 flex-initial">
      <div className="flex flex-col gap-3">
        <h3 className="text-xl uppercase text-neutral-600">Contact</h3>
        <div className="w-full h-[1px] bg-black/30"></div>
        <ul className="flex flex-col px-4 py-2 gap-2">
          <li>
            <Link
              to="/contact-us/"
              className="flex items-center gap-2 uppercase text-theme-orange"
            >
              <span>{t('common.words.contact-us')}</span>
              <ArrowRight size={20} />
            </Link>
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-3">
        <h3 className="text-xl uppercase text-neutral-600">Blog</h3>
        <div className="w-full h-[1px] bg-black/30"></div>
        <ul className="flex list-disc flex-col px-8 py-2 gap-2">
          <li>
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href={`https://polymerize.${getTLD(process.env.GATSBY_LANGUAGE_SELECTED)}/case-studies-n-white-papers/`
              }
            >
              Case Studies
            </a>
          </li>
          <li>
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href={`https://blog.polymerize.${getTLD(process.env.GATSBY_LANGUAGE_SELECTED)}/category/polymerize`
              }
            >
              Polymerize
            </a>
          </li>
          <li>
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href={`https://blog.polymerize.${getTLD(process.env.GATSBY_LANGUAGE_SELECTED)}/category/ai/ml`
              }
            >
              AI/ML
            </a>
          </li>
          <li>
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href={
                getDataManagementLink(process.env.GATSBY_LANGUAGE_SELECTED)
              }
            >
              Data Management
            </a>
          </li>
          <li>
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href={
                getIndustryNewsLink(process.env.GATSBY_LANGUAGE_SELECTED)
              }
            >
              Industry News
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
