import React from "react";
import { Container } from "components/library";
import { ChevronLeft } from "react-feather";
import { graphql } from "gatsby";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { EventContactUsBlock } from "components/pages/events/event-contact-us";
import { EventShareSection } from "components/pages/events/event-share-section";

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import EventAboutPlateform from "components/pages/events/events-about-platform";
import { RegisterForTheEvent } from "components/pages/events/event-registration";

const EventsPage = ({ location, pageContext: { eventData } }: { location: Location, pageContext: any }) => {
    const { t } = useTranslation();
    const isEnded =
        new Date().getTime() > new Date(eventData.EndDate).getTime()
    const title = eventData.Title

    const myStyles = css`
    font-family: "work-sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `

    const StyledH2 = styled.h2`
        ${myStyles}
    `;

    return (
        <main className="bg-event-bg-mobile sm:bg-size lg:bg-size-lg sm:bg-events-tab lg:bg-events bg-no-repeat -mt-28 bg-contain sm:-mt-36 pt-28 sm:pt-36 min-h-[600px] w-full">
            <Container className="flex flex-col gap-8 sm:gap-10 pt-8 pb-20 text-white">
                {/* BREADCRUMBS */}
                <div className="flex items-center gap-2">
                    <ChevronLeft className="shrink-0" size={16} />
                    <Link to="/events">{t("common.cta.back")}</Link>
                </div>

                {/* HERO */}
                <div className="flex flex-col gap-4 sm:gap-8">
                    <div className="font-light text-3xl sm:text-5xl lg:text-6xl">
                        <Trans
                            components={{ b: <b /> }}
                        >{eventData.Title}</Trans>
                    </div>

                    <div className="flex flex-col text-xl gap-1">
                        <div className="flex items-center gap-5">
                            <span className="shrink-0">
                                {eventData.Duration}
                            </span>
                        </div>
                    </div>

                    <div className="w-full aspect-video my-6 sm:my-8">
                        <img
                            src={eventData.Image}
                            className="w-[full] h-full"
                            alt={title}
                            loading="eager"
                        />
                    </div>
                </div>

                {/* CONTENT */}
                <article className={`flex gap-10 flex-col md:flex-row items-start text-neutral-900`}>
                    <div className={`flex flex-col gap-10`}>
                        {eventData.Desc && <span className="text-xl" style={{ fontSize: "20px" }}>
                            <Trans
                                components={{ b: <b />, p: <p className="mt-8" />, anchor: <a className="anchor" /> }}
                            >{eventData.Desc}</Trans>
                        </span>}
                        {eventData.Expectation && <article className="text-neutral-900 flex flex-col gap-[20px]">
                            <StyledH2 className="text-3xl ">{t('ipf-japan.whatToExpect')}</StyledH2>
                            <span className="w-full bg-gradient-to-r from-theme-blue from-0% via-theme-orange via-5% to-gray-200 to-5% h-[2px]"></span>
                            <span style={{ fontSize: "18px" }}>
                                <Trans
                                    components={{ b: <b />, p: <p className="mt-6" /> }}
                                >{eventData.Expectation}</Trans>
                            </span>
                        </article>}

                        <EventAboutPlateform />

                        {eventData.About && <article className="text-neutral-900 flex flex-col gap-[20px]">
                            <StyledH2 className="text-3xl ">{t('ipf-japan.aboutOurCompany.title')}</StyledH2>
                            <span className="w-full bg-gradient-to-r from-theme-blue from-0% via-theme-orange via-5% to-gray-200 to-5% h-[2px]"></span>
                            <span style={{ fontSize: "18px" }}>
                                <Trans
                                    components={{ b: <b />, p: <p className="mt-6" /> }}
                                >{eventData.About}</Trans>
                            </span>
                        </article>}
                    </div>
                    {isEnded && <EventContactUsBlock />}
                    {!isEnded && <RegisterForTheEvent title={title} startDate={new Date(eventData.StartDate)} endDate={new Date(eventData.EndDate)} />}
                </article>
                <article className="text-neutral-900 flex flex-col gap-[10px]">
                    {t('ipf-japan.share')}
                    <span className="flex flex-row items-center gap-[10px]">
                        <EventShareSection location={location} />
                        <span className="hidden sm:inline-flex w-full h-[1px] bg-gray-200"></span>
                    </span>
                </article>
            </Container>
        </main>
    );
};

export default EventsPage;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
