import { Link } from "gatsby";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
    Input,
    PrimaryBtn,
    InputError,
    Select,
} from "components/library";
import { EMAIL_PATTERN } from "utils/validation";
import { APIResponse } from "types/api";
import { Trans, useTranslation } from "react-i18next";
// import { SuccessIllustration } from "components/library/illustrations/success-illustration";

const API_URL = `${process.env.GATSBY_BACKEND_API_V1}/_event_registration`;
const VALIDATE_SLOTS = `${process.env.GATSBY_BACKEND_API_V1}/_event_slots_count`;

const timeSlots = [
    '10:30 to 11:00',
    '11:00 to 11:30',
    '14:00 to 14:30',
    '14:30 to 15:00',
]

const nullData = {
    name: "",
    email: "",
    organization: "",
    designation: "",
    time_slot: "",
    date: "",
};

const validateData = {
    name: false,
    email: false,
    organization: false,
    designation: false,
    time_slot: false,
    date: false,
};

type P = {
    title: string;
    startDate: Date;
    endDate: Date
};

export const RegisterForTheEvent = ({ title, startDate, endDate }: P) => {
    const { t } = useTranslation();
    const [data, setData] = useState<any>(nullData);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [datesCollection, setDatesCollection] = useState<any>([])
    const [validateFields, setValidateFields] = useState<any>(validateData)

    function handleDataChange(
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) {
        const { value, name } = e.target;
        if (name === "email") setEmailError(null);
        if (validateFields[name] && e.target.value !== "") setValidateFields({ ...validateFields, [name]: false })
        setData({ ...data, [name]: value });
    }

    useEffect(() => {
        try {
            fetch(VALIDATE_SLOTS, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
            }).then((response) =>
                response?.json()
            ).then((response) => {
                setDatesCollection(response.result.data)
            }).catch((err) => {
                console.log(err);
            })
        } catch (err) {
            console.log(
                "ERROR IN SUBMITTING REGISTER FORM",
                err,
            );
            setFormError(t("event-registration-form.error"));
        } finally {
            setLoading(false);
        }
    }, [success])

    async function handleRegisterForTheEvent(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!EMAIL_PATTERN.test(data.email))
            return setEmailError(t("event-registration-form.emailError"));


        setLoading(true);
        const body = JSON.stringify({ ...data, title });

        try {
            const response = await fetch(API_URL, {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body,
            });
            const result: APIResponse = await response.json();
            if (result.result.status === "Failed") {
                setFormError(result.result.message);
                setData({ ...data, email: "" })
                setTimeout(() => {
                    setFormError(null);
                }, 20000);
            } else {
                window.lintrk('track', { conversion_id: 14988266 });
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 20000);
                setData(nullData);
            }
            if (formError) setFormError(null)
        } catch (err) {
            console.log(
                "ERROR IN SUBMITTING REGISTER FORM",
                err,
            );
            setFormError(t("event-registration-form.error"));
        } finally {
            setLoading(false);
        }
    }

    const addDays = (date: Date, days: number) => {
        var dat = new Date(date)
        dat.setDate(dat.getDate() + days);
        return dat;
    }

    const getDates = (startDate: Date, stopDate: Date) => {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(currentDate)
            currentDate = addDays(currentDate, 1);
        }
        return dateArray.map(d => d.toISOString().slice(0, 10));
    }

    return (
        <form
            onSubmit={handleRegisterForTheEvent}
            className="bg-gradient-to-br text-[#253858] flex flex-col gap-4 p-8 rounded-md from-[#3C67F0] to-[#CA8ADA] md:min-w-[330px] drop-shadow-xl"
            style={{
                position: "sticky", top: "80px",
                background: "linear-gradient(260.88deg, #ECF2FF 0.73%, #ECF2FF 0.73%, #FFF7F1 100.5%),linear-gradient(180deg, #A6BFF7 0%, #FAD8BD 100%)"
            }} >
            <>
                <div className="flex flex-col gap-4">
                    <h3 className="text-3xl text-[#1D1D1D] text-center">{t("common.cta.registeration-form")!}</h3>
                    {success && !formError &&
                        <div className="flex items-center justify-center" style={{ color: "#0A8F3F", background: "#F6FFED", padding: "0.5rem", borderRadius: "6px" }}><span style={{ color: "#0DBB52", marginRight: "0.5rem" }}>
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg></span>Thank you for registering!</div>}
                    <div>
                        <label style={{
                            fontSize: "14px",
                            fontWeight: 400
                        }}>{`${t(
                            "event-registration-form.namePlaceholder",
                        )}*`}</label>
                        <Input
                            onChange={handleDataChange}
                            name="name"
                            isError={validateFields.name}
                            placeholder={`${t(
                                "event-registration-form.namePlaceholder",
                            )}*`}
                            onBlur={(event) => setValidateFields({ ...validateFields, name: event.target.value === "" })}
                            value={data?.name}
                        />
                        {validateFields.name && <InputError message={t(
                            "event-registration-form.requiredField",
                        )} />}
                    </div>
                    <div className="flex flex-col gap-1">
                        <label style={{
                            fontSize: "14px",
                            fontWeight: 400
                        }}>{`${t(
                            "event-registration-form.emailPlaceholder",
                        )}*`}</label>
                        <Input
                            onChange={handleDataChange}
                            isError={!!emailError}
                            type="email"
                            name="email"
                            placeholder={`${t(
                                "event-registration-form.emailPlaceholder",
                            )}*`}
                            value={data?.email}
                            onBlur={(e) => {
                                if (e.target.value === "") {
                                    setEmailError("Please enter your work email")
                                }
                                else if (!EMAIL_PATTERN.test(e.target.value)) {
                                    setEmailError(t("event-registration-form.emailError"))
                                }
                            }
                            }
                        />
                        {emailError && <InputError message={emailError} />}
                    </div>
                    <div>
                        <label style={{
                            fontSize: "14px",
                            fontWeight: 400
                        }}>{`${t(
                            "event-registration-form.organizationPlaceholder",
                        )}*`}</label>
                        <Input
                            onChange={handleDataChange}
                            name="organization"
                            placeholder={`${t(
                                "event-registration-form.organizationPlaceholder",
                            )}*`}
                            onBlur={(event) => setValidateFields({ ...validateFields, organization: event.target.value === "" })}
                            isError={validateFields.organization}
                            value={data?.organization}
                        />
                        {validateFields.organization && <InputError message={t(
                            "event-registration-form.requiredField",
                        )} />}
                    </div>
                    <div>
                        <label style={{
                            fontSize: "14px",
                            fontWeight: 400
                        }}>{`${t(
                            "event-registration-form.designationPlaceholder",
                        )}*`}</label>
                        <Input
                            onChange={handleDataChange}
                            name="designation"
                            placeholder={`${t(
                                "event-registration-form.designationPlaceholder",
                            )}*`}
                            value={data?.designation}
                            isError={validateFields.designation}
                            onBlur={(event) => setValidateFields({ ...validateFields, designation: event.target.value === "" })}
                        />
                        {validateFields.designation && <InputError message={t(
                            "event-registration-form.requiredField",
                        )} />}
                    </div>


                    <div>
                        <label style={{
                            fontSize: "14px",
                            fontWeight: 400
                        }}>{`${t(
                            "event-registration-form.datePlaceholder",
                        )}*`}</label>
                        <Select
                            name="date"
                            value={data.date}
                            placeholder={`${t(
                                "event-registration-form.datePlaceholder",
                            )}*`}
                            onChange={handleDataChange}
                            isError={validateFields.date}
                            onBlur={(event) => setValidateFields({ ...validateFields, date: event.target.value === "" })}
                        >
                            {getDates(startDate, endDate).map((date) => {
                                return (
                                    <option key={date} value={date}>
                                        {date}
                                    </option>
                                );
                            })}
                        </Select>
                        {validateFields.date && <InputError message={t(
                            "event-registration-form.requiredField",
                        )} />}
                    </div>
                    <div>
                        <label style={{
                            fontSize: "14px",
                            fontWeight: 400
                        }}>{`${t(
                            "event-registration-form.timeslotPlaceholder",
                        )}*`}</label>
                        <Select
                            name="time_slot"
                            value={data.time_slot}
                            disabled={!data.date}
                            isError={validateFields.time_slot}
                            placeholder={`${t(
                                "event-registration-form.timeslotPlaceholder",
                            )}*`}
                            onChange={handleDataChange}
                            onBlur={(event) => setValidateFields({ ...validateFields, time_slot: event.target.value === "" })}
                        >
                            {data.date
                                ? timeSlots.map((slot) => {
                                    return (
                                        <option
                                            disabled={datesCollection?.[data?.date]?.find(({ time_slot }: any) => time_slot === slot)?.count === 5}
                                            key={slot} value={slot}>
                                            {slot}
                                        </option>
                                    );
                                })
                                : null}
                        </Select>
                        {validateFields.time_slot && <InputError message={t(
                            "event-registration-form.requiredField",
                        )} />}
                    </div>
                </div>
                {formError && <div className="flex" style={{ color: "#CC1111" }}><span className="mr-2"><svg viewBox="64 64 896 896" focusable="false" data-icon="warning" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 720a48 48 0 1096 0 48 48 0 10-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z"></path></svg></span>
                    <span style={{ fontSize: "12px" }}>{formError}</span></div>}
                <div className="flex flex-col gap-3 items-center text-[#FFF]">
                    <PrimaryBtn
                        disabled={loading || !Object.keys(validateFields).map((field: string) => !!data[field]).every(value => value)}
                        type="submit"
                        className="w-full btn-cta-overwrite"
                    >
                        {formError ? "Try Again" : t("common.cta.submit")}
                    </PrimaryBtn>
                    <span className="text-sm text-center" style={{ color: "#505F79" }}>
                        <Trans
                            i18nKey={"event-registration-form.privacyPolicy"}
                            components={{
                                Link: (
                                    <Link
                                        className="text-theme-blue"
                                        to={"/privacy-policy/"}
                                    />
                                ),
                            }}
                        />
                    </span>
                </div>
            </>
            {/* {success && !formError && (
        <div className="text-2xl items-center text-center flex flex-col gap-4">
          <SuccessIllustration />
          <p>{t("event-registration-form.thankYou")}</p>
        </div>
      )} */}
        </form>
    );
};
