import { LinkedIn } from 'components/library/illustrations/LinkedIn'
import { Twitter } from 'components/library/illustrations/Twitter'
import React from 'react'
import { POLYMERIZE_LINKEDIN_PROFILE, POLYMERIZE_TWITTER_PROFILE } from 'variables'

export const EventShareSection = ({ location }: { location: Location }) => {
  return (
    <div className="inline-flex items-center gap-4">
      {/* <div className="px-3 py-1 border border-[#1B55CE] font-medium">Share</div> */}
        <a
          aria-label="Linkedin"
          href={POLYMERIZE_LINKEDIN_PROFILE}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedIn className="fill-black hover:fill-blue-700 w-100 ease-in duration-150" />
        </a>
        <a
          aria-label="Twitter"
          href={POLYMERIZE_TWITTER_PROFILE}
          target="_blank"
          rel="noreferrer"
        >
          <Twitter className="fill-black hover:fill-blue-700 w-100 ease-in duration-150" />
        </a>
    </div>
  )
}
