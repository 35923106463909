import React from "react";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { RndIll2 } from "components/library/illustrations/rndIll2";
import { RotationIll } from "components/library/illustrations/rotation-ill";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "gatsby";

const EventAboutPlateform = () => {
    const { t } = useTranslation();

    const myStyles = css`
    font-family: "work-sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `

    const StyledH2 = styled.h2`
        ${myStyles}
    `;


    const myStyles2 = css`
    color: #000;
    font-family: "work-sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18.636px;
    letter-spacing: 0.08px;
  `

    const StyledH4 = styled.h4`
    ${myStyles2}
`;

    const aboutOurPlatform = [
        [
            <Trans
                i18nKey={"ipf-japan.polymerizeLabs.para"}
                components={{
                    b: <span className="font-semibold" />,
                    Link: <Link to="/products/material-informatics/" className="text-theme-blue" />,
                }}
            />,
        ],
        [
            <Trans
                i18nKey={"ipf-japan.polymerizeConnect.para"}
                components={{
                    b: <span className="font-semibold" />,
                    Link: <Link to="/connect/" className="text-theme-blue" />,
                }}
            />,
        ]
    ];

    return (
        <article className="text-neutral-900 flex flex-col gap-[20px]">
            <StyledH2 className="text-3xl ">{t('ipf-japan.aboutOurPlatform')}</StyledH2>
            <span className="w-full bg-gradient-to-r from-theme-blue from-0% via-theme-orange via-5% to-gray-200 to-5% h-[2px]"></span>
            <div className="flex flex-col sm:flex-row w-full" style={{ gap: "10px" }}>
                <div className="flex flex-1 flex-col bg-[#F7FAFF] rounded-lg p-4 gap-3 ">
                    <span className="px-10 py-4"><RndIll2 className='jp-product-labs-svgs lg:block w-[70%] m-auto' /></span>
                    <StyledH4 className="text-xl ">{t('ipf-japan.polymerizeLabs.title')}</StyledH4>
                    <span className="w-full bg-gradient-to-r from-theme-blue from-0% via-theme-orange via-5% to-gray-200 to-5% h-[2px]"></span>
                    <span>{aboutOurPlatform[0]}</span>
                </div>
                <div className="flex flex-1 flex-col bg-[#F7FAFF] rounded-lg p-4 gap-3 ">
                    <span className="px-10 py-4"><RotationIll className='jp-product-labs-svgs lg:block  w-[70%] m-auto' /></span>
                    <StyledH4 className="text-xl ">{t('ipf-japan.polymerizeConnect.title')}</StyledH4>
                    <span className="w-full bg-gradient-to-r from-theme-blue from-0% via-theme-orange via-5% to-gray-200 to-5% h-[2px]"></span>
                    <span>{aboutOurPlatform[1]}</span>
                </div>
            </div>
        </article>
    )
}

export default EventAboutPlateform;